import { createAPI } from "../utils/request"

export const Addpermission = data => createAPI('/acl/permission/create', 'post', data) //资源新增

export const permissionList = data => createAPI('/acl/permission/list/', 'get', data) //资源列表

export const Checkpermission = data => createAPI(`/acl/permission/get/${data.id}`, 'get', data) //通过id查询资源信息

export const Editpermission = data => createAPI('/acl/permission/save/', 'post', data) //资源修改

export const Delpermission = data => createAPI(`/acl/permission/delete/${data.id}`, 'get', data) //资源删除