<template>
  <div>
      <!-- <q-card>
        <q-card-section>
          <div class="text-h6">新增角色</div>
        </q-card-section>
            <q-card-section class="column items-center adduser"> -->
                <q-form ref="myForm" greedy class="q-gutter-md add">
                    <q-input class="add" :dense='true' outlined  v-model="addroles.roleName" label="角色名称*" lazy-rules :rules="[required.roleName,required.roleNamelength]"/>
                    <q-input class="add" :dense='true' outlined  v-model="addroles.roleDesc" label="角色描述" lazy-rules :rules="[required.roleDesc]"/>
                    
                    <div class="addbtn">
                        <el-popover
                            placement="bottom"
                            width="155"
                            v-model="visible">
                            <p>未保存的数据将被丢弃, 是否继续?</p>
                            <div style="text-align: right; margin: 0">
                            <el-button size="mini" type="text" @click="visible = false">取消</el-button>
                            <el-button type="primary" size="mini" @click="quxiao">确定</el-button>
                            </div>
                            <el-button  type="text" slot="reference">取消</el-button>
                        </el-popover>
                        <!-- <q-btn flat label="取消" color="primary" v-close-popup  @click='quxiao'/> -->
                        <q-btn class="kong" label="添加" color="blue-6" @click='addroleslist'/>
                    </div>
                </q-form>
            <!-- </q-card-section>
      </q-card>  -->

  </div>
</template>

<script>
import { Addrole } from '@/api/Role.js';
import  {required}  from '@/api/components/required.js' // 表单验证规则
import { notifys } from '@/api/components/Notify.js'

export default {
    props:{
        addroles:{
            type: Object,
            required: true
        },
    },
    created(){},
    data() {
        return {
            visible: false,//控制取消提示

            required
        };
    },
    components:{},
    computed:{
    },
    watch:{
    },
    methods: {
        //关闭时对话框是触发
        quxiao(){
            this.$refs.myForm.resetValidation()
            this.visible = false
            this.$emit('addconfirm')
        },
        addroleslist(){
            this.$refs.myForm.validate().then( async outcome =>{
                if(outcome){
                    try{
                        const { data:res } = await Addrole(this.addroles)
                        console.log(res);
                        if(res.code===20000){
                            this.$refs.myForm.resetValidation()
                            this.$emit('addrolessuccess') // 表格刷新页面
                            this.$emit('addconfirm') // 清空表单内容
                            console.log(data);
                            notifys('positive',res.message)
                        }else{
                            notifys('negative',res.message)
                        }
                        
                    }catch(e){
                        console.log(e);
                    }
                }
            })
        }
    },
};
</script>

<style lang='less' scoped>
.kong{
  margin-left: 1.25rem !important;
}
.adduser{
    width: 21.875rem;
    .add{
        width: 100%;
        margin-left: 0;
        margin-top: .1875rem;
        
    }
}
.addbtn{
    display: flex;
    justify-content: flex-end;
}
</style>
