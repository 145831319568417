<template>
    <div id="Role" class="q-pa-md q-gutter-sm">
        <!-- 面包屑 -->
        <q-breadcrumbs>
            <q-breadcrumbs-el label="主页" icon="iconfont icon-zhuye" to="/orm/dashboard" />
            <q-breadcrumbs-el label="权限管理" icon="iconfont icon-quanxian"/>
            <q-breadcrumbs-el label="角色管理" icon="iconfont icon-role" to="/role/list" />
        </q-breadcrumbs>
        <!-- 表格区域 -->

        <div class='row'>
            <!-- 左边框 -->
            <div class='col-9'>
                <div class="q-pa-md">
                    <q-table
                        title="角色列表"
                        :data="roles"
                        :columns="columns"
                        :pagination="pagination"
                        :hide-pagination="true"
                        row-key="id"
                    >
                        <template v-slot:top>
                            <!-- <q-select v-model="model" :options="options" label="状态" /> -->
                            <q-input
                                outlined 
                                :dense='true'
                                v-model="roleData.roleName"
                                label="角色名称"
                                maxlength="10"
                            />
                            <!-- <q-input v-model="text" label="状态" /> -->
                            <q-btn
                                :disable='Permissionslist.filter(item=>item==="role.list").length>0?false:true'
                                class="operation"
                                color="blue-6"
                                label="重置"
                                @click="chongzhi"
                            />
                            <q-btn
                                :disable='Permissionslist.filter(item=>item==="role.list").length>0?false:true'
                                class="operation"
                                color="blue-6"
                                label="搜索"
                                @click="search"
                            />

                            <q-space />

                            <q-btn
                                :disable='Permissionslist.filter(item=>item==="role.add").length>0?false:true'
                                color="blue-6"
                                label="新增角色"
                                @click="addconfirm = true"
                            />
                        </template>
                        <template v-slot:header="props">
                            <q-tr :props="props">
                                <q-th auto-width />
                                <q-th
                                    v-for="col in props.cols"
                                    :key="col.name"
                                    :props="props"
                                >
                                    {{ col.label }}
                                </q-th>
                                <!-- 操作区 -->
                                <q-th class='my-special-class'>操作</q-th>
                            </q-tr>
                        </template>

                        <template v-slot:body="props">
                            <q-tr :props="props">
                                <q-td auto-width>
                                    <!-- <q-btn size="sm" flat color="primary" round dense @click="slottd(props)" icon='visibility'>
                            <q-tooltip :offset="[10, 10]">
                              查看
                            </q-tooltip>
                          </q-btn> -->
                                </q-td>
                                <q-td
                                  v-for="col in props.cols"
                                  :key="col.name"
                                  :props="props"
                                >
                                    {{ col.value }}
                                </q-td>
                                <!-- 操作按钮 -->
                                <q-td auto-width>
                                    <!-- body.row.id可以获取每一项的id -->
                                    <q-btn
                                      :disable='Permissionslist.filter(item=>item==="role.edit").length>0?false:true'
                                      class="czbtn"
                                      size="sm"
                                      color="blue"
                                      icon="iconfont icon-Edit"
                                      @click="edit(props.row)"
                                    ></q-btn>
                                    <q-btn
                                      :disable='Permissionslist.filter(item=>item==="role.delete").length>0?false:true'
                                      class="czbtn"
                                      size="sm"
                                      color="red-6"
                                      icon="iconfont icon-ashbin"
                                      @click="del(props.row)"
                                    ></q-btn>
                                    <q-btn
                                        class="assignbtn"
                                        :disable='Permissionslist.filter(item=>item==="role.assign").length>0?false:true'
                                        size="sm"
                                        color="yellow-7"
                                        icon="iconfont icon-setting"
                                        @click="assign(props.row)"
                                    ></q-btn>
                                </q-td>
                            </q-tr>
                        </template>
                        <!-- 无数据时显示 -->
                        <template v-slot:no-data="">
                            <div class="full-width row flex-center text-dark q-gutter-sm my-special-class">
                              <span style="color: #909399;font-size: 14px;">
                                暂无数据
                              </span>
                            </div>
                        </template>
                        <template v-slot:bottom>
                            <elementPagination
                                :total="total"
                                :queryData="roleData"
                                @changepageSize="changepageSize"
                                @changePage="changePage"
                            />
                        </template>
                    </q-table>
                </div>
            </div>
            <!-- 右边框 -->
            <div class="col-3 q-pa-md">
                <q-card bordered flat >
                    <q-card-section>
                        <div class="text-h6">分配资源</div>
                    </q-card-section>
                    <q-card-section
                        style="max-height: 50vh"
                        class="row items-center scroll"
                    >
                        <div class="fnepeibox">
                            <!-- default-expand-all 为默认打开所有分支 -->
                            <el-tree
                                :data="allPermissions"
                                show-checkbox
                                node-key="id"
                                ref="tree"
                                highlight-current
                                :default-checked-keys="defkey"
                                :props="defaultProps"
                            >
                            </el-tree>
                        </div>
                    </q-card-section>

                    <q-card-actions align="right">
                        <q-btn
                            :disable='disable'
                            icon="iconfont icon-select"
                            size="sm"
                            label="保存"
                            color="blue-6"
                            @click="Doassign()"
                        />
                    </q-card-actions>
                </q-card>
            </div>
        </div>

        <!-- 新增角色对话框组件 -->
        <el-dialog :visible.sync="addconfirm" width="25%" title="新增角色" @close="dialogclose" :close-on-click-modal='false'>
           <rolesadd
              :addroles="addroles"
              @addconfirm="dialogclose()"
              @addrolessuccess="getrolelist()"
            />
        </el-dialog>

        <!-- <q-dialog v-model="addconfirm" persistent> -->
           
        <!-- </q-dialog> -->
        <!-- 修改角色对话框组件 -->
        <q-dialog v-model="editconfirm" persistent>
            <rolesaedit
                :editroles="editroles"
                @closedialog="editconfirm = false"
                @editrolessuccess="getrolelist()"
            />
        </q-dialog>
        <!-- 查看角色对话框组件 -->
        <!-- <q-dialog v-model="checkconfirm" persistent>
        <rolescheck :checkroles='checkroles' />
      </q-dialog> -->
        <!-- 角色绑定资源对话框 -->
    </div>
</template>

<script>
import { Rolelist,Delrole,Assignrole,Doassignrole,Checkrole } from '@/api/Role.js';
import { permissionList } from '@/api/permissions.js'; // 获取接口
import rolesadd from '@/components/Role/Addroles.vue' // 添加用户组件
import rolesaedit from '@/components/Role/Editrolesa.vue' // 修改用户组件
import elementPagination from '@/components/ElementUI/elementPagination.vue' // 分页组件
import { makeDebounce } from "@/utils/debounce.js";

// import rolescheck from '@/components/Role/Checkroles.vue' // 修改用户组件
import { mapState } from "vuex"
import { notifys } from '@/api/components/Notify.js'


export default {
    created() {
      this.getrolelist()
      this.getpermissionList()
    },
    data() {
      return {
  
        //分页设置 
        pagination: {
          page: 1,
          rowsPerPage: 5, //设置默认是显示多少条,
          rowsNumber: 0, // 设置总条数
        },
        // 获取角色列表
        roleData: {
          roleName: '',
          // 当前页码
          page: 1,
          // 每页显示多少条
          pageSize: 10
        },
        total: 0, // 总数
        disable: true, //控制分配保存按钮
        checkconfirm: false, // 查看角色对话框
        assignconfirm: false, // 分配角色权限对话框
        editconfirm: false, // 修改角色信息对话框
        addconfirm: false, //新增角色对话框
        // 列表头部信息
        columns: [
          {
            name: 'roleName',
            required: true,
            label: '角色名称',
            align: 'left',
            field: row => row.roleName,
            format: val => `${val}`,
            headerClasses: 'my-special-class'
          },
          { name: 'roleDesc',align: 'center', label: '角色描述', field: 'roleDesc',headerClasses: 'my-special-class' },
          { name: 'createTime',align: 'center', label: '创建时间', field: 'createTime',headerClasses: 'my-special-class' },
        ],
        checkroles: {}, // 查看角色信息框内容

        roles: [], // 角色列表内容,

        roleId: '', // 角色id

        editroles:{}, // 修改用户所需信息

        // 添加用户所需信息
        addroles:{
          roleName:'',
          roleDesc:''
        },
        
        assignPermissions: [],// 角色拥有权限表展示
        allPermissions: [], // 所有权限展示 树状图资料
        
        defaultProps: {
          children: 'children',
          label: 'permissionName'
        },
        defkey: [],
        ticked: [],// 树状图点击分配权限时 的 id值 大分支和小分支
        expanded:[],
      };
    },
    components: {
      rolesadd,
      rolesaedit,
      elementPagination
      // rolescheck
    },
    computed: {
      ...mapState([
        'Permissionslist' // 权限词典
      ])
    },
    watch: {},
    methods: {
      
      // 获取资源列表
      async getpermissionList(){
        const { data:res } = await permissionList({
          permissionName: '',
        })
        console.log(res);
        this.allPermissions = res.data.permissions // 获取所有权限信息用于渲染树
        console.log(this.allPermissions);
      },
      // 获取角色列表
      async getrolelist(){
        const { data: res } = await Rolelist({
          roleName: this.roleData.roleName,
          page: this.roleData.page,
          pageSize: this.roleData.pageSize,
        })
        this.total = res.data.totals * 1
        console.log(res);
        this.roles = res.data.roles
      },
      //重置搜索框
      chongzhi:makeDebounce(function(){
        this.roleData.page=1
        this.roleData.roleName='' //清空输入框并刷新页面
        this.getrolelist()
      },200),
      //搜索
      search:makeDebounce(function(){
        this.roleData.page=1
        this.getrolelist()
      },200),
      //关闭新增对话框时
      dialogclose(){
        this.addroles.roleName='',
        this.addroles.roleDesc=''
        this.addconfirm = false
      },
      // 修改
      edit:makeDebounce(function(row){
        this.editconfirm = true;
        this.editroles = JSON.parse(JSON.stringify(row))
      },200),
      // 删除
      del(row){
        console.log(row);
        this.$q.dialog({
          title: '删除',
          message: `你确定要删除角色: ${row.roleName} 吗?`,
          ok: { label: '确定',color: 'red',},
          cancel: { label: '取消',flat: true},
          }).onOk(async() => {
          try{
            const {data:res} = await Delrole({id:row.id})
            console.log(res);
            if(res.code===20000){
              this.getrolelist()
              notifys('positive',res.message)
            }else{
              notifys('negative',res.message)
            }
          }catch (e){
            console.log(e);
          }
        })
      },

      // 点击左侧加号打开弹窗
      // async slottd(rows){
      //   rows.expand = !rows.expand
      //   this.checkconfirm = true
      //   const { data:res } = await Checkrole({id:rows.row.id})
      //   this.checkroles = res.data.role // 储存所点角色信息
      // },
      // 分配权限给角色
      Doassign:makeDebounce(async function(){
        try{
          // const permissionIds = this.defkey.filter((arr)=>{
          //   return arr!=0
          // })
           // 获取全选中的权限id
          const getCheckedKeys = this.$refs.tree.getCheckedKeys()
          // 获取半选择的权限id
          const getHalfCheckedKeys = this.$refs.tree.getHalfCheckedKeys()
          // 利用剩余参数 把两个数组合并并用join方法将两个数组用逗号分开变成字符串
          const rids = [...getCheckedKeys, ...getHalfCheckedKeys].join(',')
          // console.log(rids);
          const { data:res } = await Doassignrole({
            roleId: this.roleid,
            permissionIds: rids // 提交选中权限的id
          })
          console.log(res);
          if(res.code===20000){
            notifys('positive',res.message)
            // this.getpermissionList() // 获取所有权限信息用于渲染树
          }else{
            notifys('negative',res.message)
          }
        }catch(e){
          console.log(e);
        }
      },200),

      // 分配菜单的所有权限信息
      async assign(row){
        this.disable=false;
        this.defkey = []
        this.roleid= row.id // 获取嗦点击的id给roleid
        const { data:res } = await Assignrole({id:row.id})
        console.log(res);
        this.assignPermissions = res.data.assignPermissions // 获取该用户拥有的权限信息
        this.allPermissions = res.data.allPermissions // 获取所有权限信息用于渲染树
        // console.log(window.data = this.assignPermissions);
        this.getAllRights(this.assignPermissions)
        // 将id值储存在ticked
        // console.log(this.defkey); //查看id值
        
        // console.log(this.allPermissions);
      },

      // 利用递归函数 foreach循环所有的底层children 的id
      getAllRights (data) {
        let result = []
        function getLeaf (data) {
          data.forEach(item => {
            if (item.children.length===0) {
              result.push(item.id)
            } else {
              getLeaf(item.children)
            }
          })
        }
        getLeaf(data)
        return this.defkey=result
      },
      // 点击分页触发
      changepageSize(val){
        console.log(val);
        this.roleData.pageSize = val
        this.pagination.rowsPerPage = val
        this.getrolelist()
      },
      changePage(val){
        this.roleData.page = val
        this.getrolelist()
      }
      
    },
};
</script>

<style lang='less' scoped>
.operation {
    margin-right: 0.625rem;
    margin-left: 0.625rem;
}
.ratify {
    display: flex;
    align-items: center;
}
.tdstyle {
  display: flex;
  flex-wrap: wrap;
}
.border_bottom {
  border-bottom: 1px solid #eeeeee;
}
.border_top {
  border-top: 1px solid #eeeeee;
}
.fnepeibox {
  width: 50rem;
}
.Pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.625rem;
}
.czbtn {
  margin-right: 0.75rem;
}
.assignbtn:focus-within {
  background-color: #f57b00 !important;
}
/deep/.my-special-class{
  font-size: 14px;
  font-weight: bold;
  color: #909399;
}

  

</style>
