import { createAPI } from "../utils/request"

export const Rolelist = data => createAPI('/acl/role/list/', 'get', data) //获取角色分页列表

export const Addrole = data => createAPI('/acl/role/create ', 'post', data) // 新建角色

export const Delrole = data => createAPI( `/acl/role/delete/${data.id}`, 'get', data) // 删除角色

export const Editrole = data => createAPI( '/acl/role/save/', 'post', data) // 编辑角色信息

export const Assignrole = data => createAPI( `/acl/role/toAssign/${data.id}`, 'get', data) // 查询角色绑定资源

export const Doassignrole = data => createAPI( '/acl/role/doAssign/', 'get', data) // 绑定角色绑定资源

export const Checkrole = data => createAPI( `/acl/role/get/${data.id}`, 'get', data) // 绑定角色绑定资源
