<template>
  <div>
      <q-card>
        <q-card-section>
          <div class="text-h6">编辑角色</div>
        </q-card-section>
            <q-card-section class="column items-center adduser">
                <q-form ref="myForm" greedy class="q-gutter-md add">
                    <q-input class="add" :dense='true' outlined  v-model="editroles.roleName" label="角色名称*" lazy-rules :rules="[required.roleName,required.roleDesc]"/>
                    <q-input class="add" :dense='true' outlined  v-model="editroles.roleDesc" label="角色描述" lazy-rules :rules="[required.roleDesc]"/>
                    
                    <div class="addbtn">
                        <q-btn flat label="取消" color="primary" v-close-popup />
                        <q-btn label="保存" color="blue-6" @click='addroleslist'/>
                    </div>
                </q-form>
            </q-card-section>
      </q-card>

  </div>
</template>

<script>
import { Editrole } from '@/api/Role.js';
import { notifys } from '@/api/components/Notify.js'

export default {
    props:{
        editroles:{
            type: Object,
            required: true
        },
    },
    created(){},
    data() {
        return {
            required: {
                roleName: val => val && val.trim().length > 0 || '角色名称不能为空',
                roleDesc: val => val.trim().length <= 20 || '长度不可大于20个字符',
            },
        };
    },
    components:{},
    computed:{
    },
    watch:{
    },
    methods: {
        async addroleslist(){
            this.$refs.myForm.validate().then( async outcome =>{
                if(outcome){
                    try{
                        this.$refs.myForm.validate()
                        const { data:res } = await Editrole({ 
                            id: this.editroles.id,
                            roleName: this.editroles.roleName,
                            roleDesc: this.editroles.roleDesc
                        })
                        console.log(res);
                        if(res.code===20000){
                            this.$emit('editrolessuccess') // 表格刷新页面
                            this.$emit('closedialog')
                            notifys('positive',res.message)
                        }else{
                            notifys('negative',res.message)
                        }
                        
                    }catch(e){
                        console.log(e);
                    }
                }
            })
        }
    },
};
</script>

<style lang='less' scoped>
.adduser{
    width: 21.875rem;
    .add{
        width: 100%;
        margin-left: 0;
        margin-top: .1875rem;
        .addbtn{
            display: flex;
            justify-content: flex-end;
        }
    }
}
</style>
